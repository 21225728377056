import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-auth-sidebar',
  templateUrl: './auth-sidebar.component.html',
  styleUrls: ['./auth-sidebar.component.css'],
})
export class AuthSidebarComponent implements OnInit {
  changeLogs: any;

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.getChangeLogs();
  }

  getChangeLogs() {
    this.notificationService.getChangeLog().subscribe(
      (data: any) => {
        if (data.post_stream?.posts) {
          this.changeLogs = data.post_stream.posts.splice(0, 1);
        }
      },
      (err) => {}
    );
  }
}
