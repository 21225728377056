import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        const isApiUrl =
          request.url.startsWith(environment.apiBaseUrl) ||
          request.url.startsWith(environment.testApiUrl);

        if (isApiUrl) {
          if (err.status == 0 && !this.toastr.currentlyActive) {
            this.toastr.error(
              `Please check your internet connection. Cannot connect to:  ${
                request.url ? new URL(request.url).host : 'dayschedule API'
              }`,
              'Network Error',
              {
                positionClass: 'toast-bottom-full-width',
                timeOut: 60000 * 5,
                closeButton: true,
              }
            );
          } else if (err.status == 401) {
            this.authService.logout();
          }
        }
        return throwError(err);
      })
    );
  }
}
