import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(private httpClient: HttpClient) {}

  getSubscription() {
    return this.httpClient.get(environment.apiBaseUrl + `/subscription`);
  }

  getPlans() {
    return this.httpClient.get(
      environment.apiBaseUrl + `/subscription/pricing`,
      {
        responseType: 'text',
        headers: { Accept: 'text/html' },
      }
    );
  }

  addSeats(subscription_id: number, seats: number) {
    return this.httpClient.put(
      environment.apiBaseUrl + `/subscription/${subscription_id}/addseats`,
      {
        seats: seats,
      }
    );
  }

  removeSeats(subscription_id: number, seats: number) {
    return this.httpClient.put(
      environment.apiBaseUrl + `/subscription/${subscription_id}/removeseats`,
      {
        seats: seats,
      }
    );
  }

  redeemCoupon(couponCode: String) {
    return this.httpClient.post(
      environment.apiBaseUrl + `/subscription/redeem-coupon`,
      {
        coupon: couponCode,
      }
    );
  }

  applyCoupon(subscription_id: number) {
    return this.httpClient.get(
      environment.apiBaseUrl + `/subscription/${subscription_id}/coupon`
    );
  }

  cancelSubscription(subscription: any, cancellation: any) {
    const cancelReason = cancellation.comment
      ? cancellation.reason + ':' + cancellation.comment
      : cancellation.reason;
    return this.httpClient.delete(
      environment.apiBaseUrl + `/subscription/${subscription.subscription_id}`,
      {
        params: {
          cancel_reason: cancelReason,
        },
      }
    );
  }

  createSession(data: any): Observable<any> {
    return this.httpClient.post(
      environment.apiBaseUrl + '/subscription/create-checkout-session',
      data
    );
  }

  getSubscriptionUrl(subscrId: number) {
    return this.httpClient.get(
      environment.apiBaseUrl + `/subscription/${subscrId}/portal`
    );
  }
}
