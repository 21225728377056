import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    const chunkFailedMessage = /ChunkLoadError/;

    if (chunkFailedMessage.test(error.message)) {
      if (confirm('New version available, please refresh.')) {
        window.location.reload();
      }
    }
    console.error(error);
  }
}
