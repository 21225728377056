<div class="row m-0 vh-100">
  <div class="col-lg-4 col-md-5 bg-brand-primary p-0">
    <div class="mt-5 mx-4 mx-md-5">
      <img class="img-fluid" width="200" height="auto" src="https://cdn.dayschedule.com/img/dayschedule-logo.png" alt="DaySchedule" />
    </div>
    <div class="card my-3 mx-4 mx-md-5 text-gray-900">
      <div class="card-body">
        <h1 class="h3">Join DaySchedule</h1>
        <p>Join your organization account</p>
        <form class="user" #form="ngForm" (ngSubmit)="onSubmit(form)" ngNativeValidate>
          <div class="form-group">
            <input
              type="text"
              placeholder="Name*"
              class="form-control"
              id="name"
              aria-describedby="nameHelp"
              name="name"
              [(ngModel)]="user.name"
              required
            />
          </div>
          <div class="form-group">
            <input
              type="password"
              placeholder="Password*"
              class="form-control"
              name="password"
              id="password"
              [(ngModel)]="user.password"
              required
              minlength="8"
              #password="ngModel"
            />
          </div>
          <div class="form-group">
            <input
              type="password"
              placeholder="Confirm password*"
              class="form-control"
              name="confirmpassword"
              id="confirmpassword"
              [(ngModel)]="user.confirm_password"
              required
              minlength="8"
            />
          </div>
          <button type="submit" class="btn btn-primary btn-block mt-3" [disabled]="loading">
            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
            Join
          </button>
          <hr />
          <div>Already have an account? <a routerLink="/login">Go to Login &rarr;</a></div>
        </form>
      </div>
    </div>
  </div>
  <div class="col-lg-8 col-md-7 d-none d-md-block bg-white border-left">
    <app-auth-sidebar></app-auth-sidebar>
  </div>
</div>
