import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

import { LoginComponent } from './auth/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ResetComponent } from './auth/reset/reset.component';
import { JoinComponent } from './auth/join/join.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { VerificationComponent } from './auth/verification/verification.component';
import { RedeemCouponComponent } from './redeem-coupon/redeem-coupon.component';
import { LevelType, PermissionType, RoleType } from './model/user';
import { MenuComponent } from './home/menu/menu.component';

const routes: Routes = [
  // Public pages
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { title: 'Forgot Password' },
  },
  { path: 'signup', component: SignupComponent, data: { title: 'Sign up' } },
  {
    path: 'reset-password',
    component: ResetComponent,
    data: { title: 'Reset password' },
  },
  {
    path: 'verification',
    component: VerificationComponent,
    data: { title: 'Verification' },
  },
  { path: 'join', component: JoinComponent, data: { title: 'Join' } },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
    data: { menu: false, title: 'Onboarding', icon: '' },
  },
  {
    path: 'redeem-coupon',
    component: RedeemCouponComponent,
    canActivate: [AuthGuard],
    data: { menu: false, title: 'Reedem Coupon', icon: '' },
  },
  {
    path: 'menu',
    component: MenuComponent,
    canActivate: [AuthGuard],
    data: { menu: false, title: 'Menu', icon: '' },
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    data: { menu: true, title: 'Dashboard', icon: 'fa-chart-bar' },
  },
  {
    path: 'resources',
    loadChildren: () =>
      import('./resources/resources.module').then((m) => m.ResourcesModule),
    data: {
      menu: true,
      title: 'Resources',
      icon: 'fa-th-large',
      permissions: [
        RoleType.Admin,
        RoleType.Owner,
        PermissionType['resource.read'],
      ],
    },
  },
  {
    path: 'bookings',
    loadChildren: () =>
      import('./bookings/bookings.module').then((m) => m.BookingsModule),
    data: {
      menu: true,
      title: 'Bookings',
      icon: 'fa-calendar-check',
      permissions: [
        RoleType.Admin,
        RoleType.Owner,
        PermissionType['booking.read'],
      ],
    },
  },
  {
    path: 'availability',
    loadChildren: () =>
      import('./availability/availability.module').then(
        (m) => m.AvailabilityModule
      ),
    data: { menu: true, title: 'Availability', icon: 'fa-calendar-day' },
  },
  {
    path: 'calendars',
    loadChildren: () =>
      import('./calendars/calendars.module').then((m) => m.CalendarsModule),
    data: { menu: true, title: 'Calendars', icon: 'fa-calendar-alt' },
  },
  {
    path: 'crm',
    loadChildren: () => import('./crm/crm.module').then((m) => m.CrmModule),
    data: {
      menu: true,
      title: 'CRM',
      icon: 'fa-address-book',
      permissions: [
        RoleType.Admin,
        RoleType.Owner,
        PermissionType['contact.read'],
      ],
    },
  },
  {
    path: 'workflows',
    loadChildren: () =>
      import('./workflows/workflows.module').then((m) => m.WorkflowsModule),
    data: {
      menu: true,
      title: 'Workflows',
      icon: 'fa-project-diagram',
      permissions: [
        RoleType.Admin,
        RoleType.Owner,
        PermissionType['workflow.read'],
      ],
    },
  },
  {
    path: 'connections',
    loadChildren: () =>
      import('./connection/connection.module').then((m) => m.ConnectionModule),
    data: { menu: true, title: 'Connections', icon: 'fa-network-wired' },
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
    data: { menu: true, title: 'Pages', icon: 'fa-link' },
  },
  {
    path: 'campaigns',
    loadChildren: () =>
      import('./campaign/campaign.module').then((m) => m.CampaignModule),
    data: {
      menu: true,
      title: 'Campaigns',
      icon: 'fa-paper-plane',
      level: LevelType.Level1,
    },
  },
  {
    path: 'billings',
    loadChildren: () =>
      import('./billings/billings.module').then((m) => m.BillingsModule),
    data: {
      menu: true,
      title: 'Billings',
      icon: 'fa-credit-card',
      permissions: [RoleType.Owner],
    },
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsModule),
    data: { menu: true, title: 'Settings', icon: 'fa-cogs' },
  },

  // Upcoming feature
  // {
  //   path: 'tasks',
  //   loadChildren: () => import('./tasks/tasks.module').then((m) => m.TasksModule),
  //   data: { menu: true, title: 'Tasks', icon: 'fa-network-wired', level: LevelType.Level3 },
  // },
  {
    path: 'surveys',
    loadChildren: () =>
      import('./survey/survey.module').then((m) => m.SurveyModule),
    data: {
      menu: true,
      title: 'Surveys',
      icon: 'fa-poll-h',
      level: LevelType.Level1,
    },
  },
  // Admin only
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    data: {
      menu: true,
      title: 'Admin',
      icon: 'fa-user-lock',
      level: LevelType.Level1,
    },
  },

  // Home page, 404
  { path: 'contacts', redirectTo: '/crm/contacts' },
  { path: '', pathMatch: 'full', redirectTo: 'bookings' },
  { path: '**', component: NotFoundComponent, data: { title: 'Error' } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
