import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

declare var gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(router: Router) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.event('page_view', {
          page_path: e.url,
        });
      }
    });
  }

  public event(eventName: string, params: {}) {
    try {
      gtag('event', eventName, params);
    } catch {}
  }
}
