<div class="row m-0 vh-100">
  <div class="col-lg-4 col-md-5 bg-brand-primary p-0">
    <div class="mt-5 mx-4 mx-md-5">
      <img class="img-fluid" width="200" height="auto" src="https://cdn.dayschedule.com/img/dayschedule-logo.png" alt="DaySchedule" />
    </div>
    <div class="card my-3 mx-4 mx-md-5 text-gray-900">
      <div class="card-body">
        <h1 class="h3">Forgot your password?</h1>
        <p>Enter your email to get a password reset link</p>
        <form class="user" #form="ngForm" (ngSubmit)="onSubmit()" ngNativeValidate>
          <div class="form-group">
            <input
              type="email"
              placeholder="Email*"
              class="form-control"
              id="email"
              aria-describedby="email"
              name="email"
              [(ngModel)]="user.email"
              required
            />
          </div>
          <button type="submit" class="btn btn-primary btn-block" [disabled]="loading">
            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
            Send Reset Link
          </button>
        </form>
        <hr />
        <div class="mt-2">Already have an account? <a routerLink="/login">Go to Login &rarr;</a></div>
      </div>
    </div>
  </div>

  <div class="col-lg-8 col-md-7 d-none d-md-block bg-white border-left">
    <app-auth-sidebar></app-auth-sidebar>
  </div>
</div>
