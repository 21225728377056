export enum OTPPurpose {
  signup = 'signup',
  delete_account = 'delete_account',
  change_email = 'change_email'
}

export class OtpVerification {
  purpose: OTPPurpose;
  code?: number;
  data?: Record<string, string>;
}
