export const environment = {
  production: true,
  apiBaseUrl: 'https://api.dayschedule.com/v1',
  testApiUrl: 'https://api.dayschedule.in/v1',
  workerUrl: 'https://api.dayschedule.uk/v1',
  stripeKey: 'pk_live_51IaZoxSFMLczVMp3jGgJirSp5B1p5kz8eNfPEH51tnK4BYmc95q9XiwHALsyXgENKCFUFWUicB4OHgXtOTcMEk2100NdhUGZOK',
  appBaseUrl: 'https://app.dayschedule.com',
  recaptchaV3Key: '6LemDpsjAAAAAM4Xo-zXYmhJFF6F9jOD7Z2txHfN',
  googleMapkey: 'AIzaSyDq3wIXMfnA0uunvYybFRet_k-0Gu4vJFI',
  firebase: {
    apiKey: "AIzaSyDAJzKqD6rGYTlAozhEacvHko51FAuiEaE",
    authDomain: "dayschedule.firebaseapp.com",
    projectId: "dayschedule",
    storageBucket: "dayschedule.appspot.com",
    messagingSenderId: "35818022409",
    appId: "1:35818022409:web:9df903245794d33c89bad8"
  },

  getDomain: (hostname: string) => {
    if (environment.production) {
      return hostname.replace('app.', '');
    } else {
      return 'localhost';
    }
  },

  getApiBaseUri: (hostname: string) => {
    if (environment.production) {
      return `https://${hostname.replace('app.', 'api.')}/v1`;
    } else {
      return environment.apiBaseUrl;
    }
  },
};
