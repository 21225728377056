<div class="container-fluid">
  <ng-container *ngFor="let type of types">
    <h1 class="h5 mb-2">{{ type.name }}</h1>
    <div class="row">
      <ng-container *ngFor="let menu of menus">
        <div *ngIf="checkPermission(menu, type)" class="col-4 mb-3 text-center">
          <a
            [routerLink]="[menu.url]"
            class="card shadow bg-light text-decoration-none"
          >
            <div class="card-body py-2">
              <i
                *ngIf="menu.icon"
                class="fas fa-fw mb-1"
                [ngClass]="menu.icon"
              ></i>
              <span class="d-block text-gray-800 small text-nowrap">{{
                menu.name
              }}</span>
            </div>
          </a>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
