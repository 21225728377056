<!-- Sidebar -->
<ul
  class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled d-none d-md-block"
  id="accordionSidebar"
  *ngIf="!isMobileApp"
>
  <!-- Sidebar - Brand -->
  <a
    class="sidebar-brand d-flex align-items-center justify-content-center"
    href="/"
  >
    <div class="sidebar-brand-icon mx-3">
      <img
        title="DaySchedule"
        alt="DaySchedule"
        src="https://cdn.dayschedule.com/img/dayschedule-64.png"
        width="40"
      />
    </div>
  </a>

  <!-- Divider -->
  <hr class="sidebar-divider my-0" />

  <!-- Divider -->
  <hr class="sidebar-divider" />

  <!-- Nav Item - Pages Collapse Menu -->
  <ng-container *ngFor="let menu of menus">
    <li
      class="nav-item"
      routerLinkActive="active"
      *ngIf="checkPermission(menu)"
    >
      <a class="nav-link collapsed" [routerLink]="menu.url">
        <i *ngIf="menu.icon" class="fas fa-fw" [ngClass]="menu.icon"></i>
        <span>{{ menu.name }}</span>
      </a>
    </li>
  </ng-container>

  <div class="mt-auto">
    <hr class="sidebar-divider d-none d-md-block" />
    <ng-container *ngFor="let menu of menus2">
      <li
        *ngIf="checkPermission(menu)"
        class="nav-item"
        routerLinkActive="active"
      >
        <a class="nav-link collapsed" [routerLink]="menu.url">
          <i *ngIf="menu.icon" class="fas fa-fw" [ngClass]="menu.icon"></i>
          <span>{{ menu.name }}</span>
        </a>
      </li>
    </ng-container>
  </div>

  <!-- Divider -->
  <hr class="sidebar-divider" />
</ul>

<!-- End of Sidebar -->

<!-- Mobile only Sidebar -->
<div class="mobile-nav d-block d-md-none" *ngIf="!isMobileApp">
  <nav class="navbar px-2 navbar-expand bg-gradient-primary">
    <ul class="navbar-nav nav-fill w-100">
      <ng-container *ngFor="let menu of menus3">
        <li
          class="nav-item"
          routerLinkActive="active"
          *ngIf="checkPermission(menu)"
        >
          <a class="nav-link collapsed" [routerLink]="menu.url">
            <i *ngIf="menu.icon" class="fas fa-fw" [ngClass]="menu.icon"></i>
            <span>{{ menu.name }}</span>
          </a>
        </li>
      </ng-container>
      <li class="nav-item">
        <a [routerLink]="['/menu']" class="nav-link">
          <i class="fas fa-fw fa-bars"></i>
          <span>Menu</span>
        </a>
      </li>
    </ul>
  </nav>
</div>
<!-- End of Mobile only Sidebar -->
