import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { NgForm } from '@angular/forms';
import { ToastrHelper } from '../helper/toastr-helper';
import { AuthenticateRequest } from '../model/models';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { ConfirmOtpComponent } from '../_shared/confirm-otp/confirm-otp.component';
import { OTPPurpose } from '../model/otpVerification';
import { timer } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  user = new AuthenticateRequest();
  loading: boolean = false;
  returnUrl: string;
  googleAuthUri: string;

  constructor(
    private authService: AuthService,
    private toastr: ToastrHelper,
    private router: Router,
    private activateRouter: ActivatedRoute,
    private modalService: NgbModal,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.googleAuthUri = `${environment.getApiBaseUri(
      this.document.location.hostname
    )}/oauth/google?reason=consent`;
  }

  ngOnInit(): void {
    const authUser = this.authService.getUser();
    const authToken = this.authService.getToken();
    const isLoggedIn = authUser && authToken;
    if (isLoggedIn) {
      this.autoRedirect(authUser);
    }
  }

  onSubmit(form: NgForm) {
    this.userLogin(this.user);
  }

  userLogin(user: AuthenticateRequest) {
    this.loading = true;
    this.authService.login(user).subscribe(
      (data) => {
        this.authService.saveToken(data.token);
        if (!data.is_verified) {
          timer(100).subscribe((x) => {
            this.otpVerification();
          });
        } else {
          this.authService.saveUser(data);
          this.toastr.handleSuccess(`Signin successfully`);
          this.autoRedirect(data);
        }
        this.loading = false;
      },
      (err) => {
        this.toastr.handleError(err);
        this.loading = false;
      }
    );
  }

  otpVerification() {
    const modalRef = this.modalService.open(ConfirmOtpComponent);
    modalRef.componentInstance.otp = { purpose: OTPPurpose.signup };
    modalRef.result.then(
      (result) => {
        this.authService.saveToken(result.token);
        this.authService.saveUser(result);
        this.autoRedirect(result);
      },
      (reason) => {}
    );
  }

  autoRedirect(authUser: any): void {
    this.setCrispData(authUser);
    const { returnUrl } = this.activateRouter.snapshot.queryParams;
    console.log('r', returnUrl);
    if (
      returnUrl &&
      [environment.apiBaseUrl, environment.testApiUrl].some((url: string) =>
        returnUrl.startsWith(url)
      )
    ) {
      window.open(returnUrl, '_self');
    } else {
      const redirectURL = this.authService.getRedirectUrl(returnUrl);
      this.router.navigate([redirectURL]);
    }
  }

  setCrispData(authUser: any) {
    try {
      const $crisp = (window as any)['$crisp'];
      $crisp.push(['safe', true]);
      $crisp.push(['set', 'user:email', authUser.email]);
      $crisp.push(['set', 'user:nickname', authUser.name]);
      $crisp.push([
        'set',
        'session:data',
        [
          [
            ['account_id', authUser.account_id],
            ['user_id', authUser.user_id],
            ['plan', authUser.plan],
            ['status', authUser.status],
          ],
        ],
      ]);
    } catch {}
  }
}
