import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastrHelper {
  constructor(private toastr: ToastrService) {}

  handleSuccess(message: string) {
    this.toastr.success(message, 'Success', {
      closeButton: true,
      timeOut: 5000,
      enableHtml: true,
      progressBar: true,
    });
  }

  handleError(response: any) {
    let erorMessage: string = '';
    if (response) {
      if (typeof response == 'string') {
        erorMessage = response;
      } else if (response.error?.details) {
        let errors: string = '';
        for (let prop of response.error.details) {
          errors += '<li>' + prop.message + ' </li>';
        }
        erorMessage =
          '<p>' +
          response.error.message +
          '</p><p>Details : </p><ol>' +
          errors +
          '</ol>';
      } else if (response.error?.message) {
        erorMessage = response.error.message;
      } else if (response.message) {
        erorMessage = response.message;
      } else if (response.status === 0) {
        erorMessage = 'Network error: Please check your internet connection';
      } else {
        erorMessage = `statusCode: ${
          response.status
        }, response: ${JSON.stringify(response.error)}`;
      }
    } else {
      erorMessage =
        'Network error: Please check your internet connection and try again';
    }

    this.toastr.error(erorMessage, 'Error', {
      closeButton: true,
      timeOut: 5000,
      enableHtml: true,
      progressBar: true,
    });
  }
}
