<!-- Topbar -->
<nav
  class="navbar navbar-expand topbar mb-4 shadow"
  [ngClass]="{ 'bg-gradient-primary px-2': isMobile, 'navbar-light bg-white': !isMobile }"
  *ngIf="!isMobileApp"
>
  <div class="mr-3 d-block d-md-none">
    <img title="DaySchedule" alt="DaySchedule" src="https://cdn.dayschedule.com/img/dayschedule-logo.svg" width="160" />
  </div>

  <div class="d-none d-md-block" *ngIf="authUser.domain">
    <div class="mr-3 d-block d-md-none">
      <img title="DaySchedule" alt="DaySchedule" src="https://cdn.dayschedule.com/img/dayschedule-48.png" width="40" />
    </div>
    <div class="input-group input-group-sm mr-3">
      <input type="text" class="form-control border-primary" [value]="authUser.domain + '.dayschedule.com'" />
      <div class="input-group-append">
        <button class="btn btn-outline-primary" type="button" (click)="copyService.copy('https://' + authUser.domain + '.dayschedule.com')">
          <i class="fas fa-copy"></i>
        </button>
        <button class="btn btn-outline-primary" type="button" (click)="openEmbedModal(authUser.domain)">
          <i class="fas fa-code"></i>
        </button>
        <a class="btn btn-outline-primary" type="button" target="_blank" [href]="'https://' + authUser.domain + '.dayschedule.com'">
          <i class="fas fa-external-link-alt"></i>
        </a>
      </div>
    </div>
  </div>

  <!-- Topbar Navbar -->
  <ul class="navbar-nav ml-auto">
    <li class="nav-item d-none d-sm-block" *ngIf="authUser.plan == 'Free'">
      <a class="nav-link" routerLink="/billings"><span class="badge badge-danger">Upgrade your plan &rarr;</span></a>
    </li>
    <div class="topbar-divider d-none d-sm-block"></div>
    <li class="nav-item">
      <a target="_blank" href="https://play.google.com/store/apps/details?id=com.dayschedule" class="nav-link text-success" title="Android app">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABj0lEQVR4nO2WzUoDQQzHR30FvYpP4UWhTeqhXtRLwUfwInh1k7L4AfYVWlTwpOxFmiwKPkDxDfQtqj1qtTLbVul2C1s7i1T6hxxmhkl+m5lsxpiZpk4dM/erNRcihVMWuE8K5Pv+PCk8sOJJZgDlen6NBT+5jmckUCHBq65BhUKskuC7p7iaGYAnuEmKTVbsJBkJNiksFDMJzoq7pPDBgm+k+JwA8GTXWKFNIZacBj+8W18igdfoKxUa/m1uJQ7gy8YyCT5GY4EXX3KLzgA8xYPBgNAezsDgHIWw7wygLLjHgsE4ZveYqRUL7thzZMFabxyMuvkjTTCI9iqcW19ePb+dHkDhpn+uDgCiu0EC1+NkIOg7mhxgcDwDSCWeHYHiZe/ytBxcwlavUV2ky78xJvrXC9ZYYWtSAFv/1pf1mRogrkkAnIgEj8cGUDhyBlAKSgv2oWH7/He3U2jYcWQKjZ8uaOcKRbvHZCFOSHG8bDMV/z0ADDWXePPKFkCw1n2Ydku0X2p2jgSrmQPM9O/0BSiW6KwHL1m2AAAAAElFTkSuQmCC"
        />
      </a>
    </li>
    <li class="nav-item">
      <a target="_blank" href="https://apps.apple.com/us/app/dayschedule-appointment-app/id6444792037" class="nav-link text-gray-800" title="iOS app">
        <img
          class="d-none d-md-block"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABS0lEQVR4nO3WP0tcQRSH4YeoEbEIBNKIhQYC1pIipLJKtZWFVcBK1sI2YJHCxkYQ7BUJsRQJKSImplBEcFu/wSIpolXIHyGwGgZusSwshHvGKyz7g1MNzPsyc2Y49FAGMIdtDFcNf4JT3OIaD6qED6FRwFN9UXEW2+A3mKla4KiAt7DkHvIVH/DiLiEjWMAuDrGJWtH5KY9Qx0ec4wRreJoD/hzNtntur+84w+8u63/xNgKfwo8um/9vpcZ8WVbgOAM8XV2pTAfhqXYEspxB4FlEYCsIvxLMXlDgIirwLiiQnuBoRGA1Qw/MRwRmMwh8w1hZgcfFMUYlmpGP6FMGgVSXGCwj8CqTwLpAPgfhfzAeEZjAr4DAGxlSLwk/aJsZwlnp2LxVTMLvsY+fHeuNYlDJmho2imOd7FhLv97rYhJKJ/YwN7yffnoz/wDEgy9VYKCOQAAAAABJRU5ErkJggg=="
        />
        <img
          class="d-block d-md-none"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABjklEQVR4nL3XP0gXUQDA8SspMBFd2gwpCGoKhDZxaNFIA6kIGpyiImiyoaGhXEJpCSqaBN3cizYbChxrCJpKpKVBkVSECPzIowvyMH3n7737bsc93vfLvfvzrigSgkM4h5Mp540Cw/jsD3eLJsETOxlpUn6nIt9Ad1PyLvysBDxtRB7AzYr8EzqKpsDLf+RvcTyHpBMXcQ9j6AuPW3nuNl7gQnncjUvhKcAt9OPwQcXtmNpljQPf8QxXMVDK3uDXLmMXcaOu/AQ+Sst01NVARwZ5YAvX6t5YqfiNKzHynv+sY6s8POhbLQXhJmyLDZiRnskoeQDvMgSMFbFgIUPA9ToB8xkCHtUJmM0QsFAn4IE8DMUG9GcK+IEzMQFtWMkUET5gozERz+Xl8n4BZ8sPRw6WcDTmKsxlCoh7KaEXm4nl7//upGIjxhPK13E6Wh4Iuxe8TiDfqr0lq+yOPuwx+Rq+7bFcQX6/aAUcwQRWy0m/4jFOVcadxyssl+O+YLAlebFTEP58j0WObU8mzs02Fi0jpzGQjuMAAAAASUVORK5CYII="
        />
      </a>
    </li>
    <div class="topbar-divider d-none d-sm-block"></div>
    <li class="nav-item d-none d-sm-block">
      <a target="_blank" href="https://dayschedule.com/videos" class="nav-link text-danger" title="Watch video tutorials">
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABZUlEQVR4nO2WPUvDUBSGX9vFVbq4tP4CcXKQ3HM7xMlBqdBR/AEKVVcX/4CKk6jg7sfm7qKdWnD3Y9JVoYo4KH0laVKHJG1STeNwHzhwuUnOeeB+5AAGgyEEWihRY5EaNSrsUuGcCnUKmhQ8UPBEwQsVvtxwxp0551nTe/eMgh03h5PLQql/4SryVDiigCnFIYFctIDCRorF/ViLFhA0hiDQ6CXQSl1A4Y3ASLC4jULsJJsVcm5scIkyxoMCGpOxE1yekq1ncq9GlvPJBTSmwwRmEgn43N6QqzrpMthhJ2B2IAGf+gVZnYj3vYVK2AZc+JWAw8c7ebxF2qP9cixnK6CwlO0SaMz/7SZckaQnQWd7DC1MZXsR2SgEBIZ2FQte8X9/RgrrqQto1KIFgBwFBxS0UyjepsJ+z4bEh4JityUTbFPhhIIrr926o8Kj15J9utEZO3P3Xtt2HWjJBMVuAYPBgB++AWE6vmUUPpDpAAAAAElFTkSuQmCC"
        />
      </a>
    </li>
    <div class="topbar-divider d-none d-sm-block"></div>

    <li class="nav-item dropdown no-arrow">
      <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <span class="mr-2 d-none d-lg-inline text-gray-600 small">{{ authUser.name }}</span>
        <img class="img-profile rounded-circle" [src]="authUser.avatar != null ? authUser.avatar : 'https://cdn.dayschedule.com/icon/avatar.png'" />
      </a>
      <!-- Dropdown - User Information -->
      <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
        <a class="dropdown-item" routerLink="settings/profile">
          <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
          Profile
        </a>
        <a class="dropdown-item" routerLink="settings">
          <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
          Settings
        </a>
        <a class="dropdown-item" routerLink="/settings/my-availability">
          <i class="fas fa-calendar fa-sm fa-fw mr-2 text-gray-400"></i>
          Schedule & Availability
        </a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" target="_blank" href="https://dayschedule.com/docs/">
          <i class="fas fa-info-circle fa-sm fa-fw mr-2 text-gray-400"></i>
          Documentation
        </a>
        <a class="dropdown-item" target="_blank" href="https://dayschedule.com/contact">
          <i class="far fa-address-book fa-sm fa-fw mr-2 text-gray-400"></i>
          Contact us
        </a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item text-warning" target="_blank" href="https://github.com/dayschedule/public-roadmap#suggest-a-feature">
          <i class="far fa-star fa-sm fa-fw mr-2"></i>
          Suggest a Feature
        </a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="#" data-toggle="modal" (click)="logoutUser()" data-target="#logoutModal">
          <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          Logout
        </a>
      </div>
    </li>
  </ul>
</nav>

<div *ngIf="promoAd" class="p-3 rounded rounded-3 bg-info-subtle promo-container">
  <div class="d-flex justify-content-start">
    <div class="ml-3">
      <div class="d-flex justify-content-between align-items-center">
        <h4 class="text-dark text-gray-900">{{ promoAd.heading }}</h4>
        <button type="button" class="btn btn-link text-muted" (click)="closeAds()">
          <i class="bi bi-x-lg"></i>
        </button>
      </div>
      <p class="text-dark">{{ promoAd.message }}</p>
      <div class="d-flex justify-content-start">
        <a *ngIf="!promoAd.url.startsWith('https://')" (click)="closeAds()" [routerLink]="promoAd.url" class="btn btn-primary mr-3">
          <span>{{ promoAd.cta }}</span>
        </a>
        <a *ngIf="promoAd.url.startsWith('https://')" (click)="closeAds()" [href]="promoAd.url" target="_blank" class="btn btn-primary mr-3">
          <span>{{ promoAd.cta }}</span>
        </a>
        <a (click)="closeAds()" class="btn btn-link">
          <span>Not now</span>
        </a>
      </div>
    </div>
  </div>
</div>

<ng-template #changeLogModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title text-gray-800">New Features and Improvement</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body changelog">
    <div *ngFor="let change of changeLogs.post_stream.posts" class="row">
      <div class="col-md-2">
        <div class="mb-3 mt-3">
          <div *ngIf="change.description.includes('new') || change.description.includes('added'); then newFeture; else improvement"></div>
          <ng-template #newFeture> <span class="badge mr-2 badge-majenta">New feature</span> </ng-template>
          <ng-template #improvement> <span class="badge mr-2 badge-primary">Improvement</span> </ng-template>
        </div>
        <time class="h5">{{ change.prettyDate }}</time>
      </div>
      <div class="col-md-10 mb-5 text-gray-900">
        <div [innerHTML]="change.html"></div>
        <div class="mt-5">
          <img src="https://docs.agenty.com//user_avatar/docs.agenty.com/admin/48/433_2.png" class="rounded-circle mr-2" width="48" height="48" />
          <span class="text-muted">Vikash, CEO</span>
        </div>
        <hr />
      </div>
    </div>
  </div>
</ng-template>
