import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { ProfileService } from './profile.service';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  currentMessage = new BehaviorSubject<any>(null);

  constructor(
    private afMessaging: AngularFireMessaging,
    private profileService: ProfileService,
    private router: Router,
    private authService: AuthService
  ) {}

  async requestPermission() {
    await this.askPermission();
    const token = await this.getToken();
    const authUser = this.authService.getUser();
    if (token && authUser && !authUser.web_token) {
      this.profileService.updateToken({ web_token: token }).subscribe(
        (data: any) => {},
        (err) => {}
      );
    }
    return token;
  }

  listenToMessages(): void {
    this.afMessaging.messages.subscribe((payload) => {
      console.log('New message received.', payload);
      this.currentMessage.next(payload);
      this.showNotification(payload);
    });
  }

  private async askPermission(): Promise<void> {
    try {
      const decision = await firstValueFrom(this.afMessaging.requestPermission);
      console.info('Notification permission:', decision);
    } catch (error) {
      console.error('Unable to get permission', error);
    }
  }

  private async getToken(): Promise<string | null> {
    try {
      return await firstValueFrom(this.afMessaging.getToken);
    } catch (error) {
      return null;
    }
  }

  private showNotification(payload: any): void {
    const notificationTitle = payload.notification?.title || 'New Message';
    const notificationOptions = {
      body: payload.notification?.body || 'You have a new message',
      icon: 'https://cdn.dayschedule.com/img/dayschedule-64.png', // Make sure this path is correct
    };

    console.log(
      `Showing notification: ${notificationTitle}`,
      notificationOptions
    );

    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
    } else if (Notification.permission === 'granted') {
      const notification = new Notification(
        notificationTitle,
        notificationOptions
      );
      notification.onclick = () => this.handleNotificationClick(payload);
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          const notification = new Notification(
            notificationTitle,
            notificationOptions
          );
          notification.onclick = () => this.handleNotificationClick(payload);
        }
      });
    }
  }

  private handleNotificationClick(payload: any): void {
    const url = payload.notification?.click_action || payload.data?.url;
    if (url) {
      if (url.startsWith('http')) {
        window.open(url, '_blank');
      } else {
        this.router.navigateByUrl(url);
      }
    }
  }
}
