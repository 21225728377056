import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CopyToClipboardService } from 'src/app/services/copy-to-clipboard.service';

@Component({
  selector: 'app-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.css'],
})
export class EmbedComponent implements OnInit {
  @Input() domain: string;

  code: string;
  options: EmbedOptions;
  constructor(public activeModal: NgbActiveModal, private copyService: CopyToClipboardService) {}

  ngOnInit(): void {
    this.domain = this.domain?.split('.')[0].replace('https://', '');
    this.options = {
      url: `https://${this.domain}.dayschedule.com`,
      type: 'popup',
      color: {
        primary: '#0f0980',
        secondary: '#afeefe',
      },
    };
    this.codeGenerator();
  }

  codeGenerator() {
    this.options.url = `https://${this.domain}.dayschedule.com`;
    if (this.options.type === 'inline') {
      this.code = `<dayschedule-widget url="${this.options.url}"></dayschedule-widget>`;
    } else {
      this.code = `<script>
  daySchedule.initPopupWidget(${JSON.stringify(this.options, null, '\t')})
</script>`;
    }
  }

  copy() {
    this.copyService.copy(this.code);
  }
}

export interface EmbedOptions {
  url: string;
  type: string;
  color: ColorOptions;
  hideHeader?: boolean;
}

export interface ColorOptions {
  primary: string;
  secondary: string;
}
