<div class="modal-header">
  <div class="modal-title h5 text-gray-800">{{ title }}</div>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss()">
    <span>&times;</span>
  </button>
</div>
<form #ngForm (ngSubmit)="verifyOtp()" ngNativeValidate>
  <div class="modal-body">
    <div class="form-group">
      <p>{{ description }}</p>
    </div>
    <div class="form-group">
      <label for="otp" class="font-weight-bold">Verification code</label>
      <input type="number" class="form-control" [(ngModel)]="otp.code" name="otp" minlength="4" maxlength="6" required />
      <p class="help-text small">Enter the one time verification(OTP) sent on your email</p>
    </div>
  </div>
  <div class="modal-footer bg-light py-1">
    <button class="btn" [ngClass]="btnClass" type="submit">
      {{ btnText }}
      <i class="fa fa-spinner fa-spin ml-2" *ngIf="loading"></i>
    </button>
  </div>
</form>
