import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { User } from '../../model/models';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CopyToClipboardService } from '../../services/copy-to-clipboard.service';
import { NotificationService } from '../../services/notification.service';
import { EmbedComponent } from '../embed/embed.component';
import { ProfileService } from '../../services/profile.service';
import { Ads } from '../../model/ads';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css'],
})
export class TopbarComponent implements OnInit {
  isMobileApp: boolean;
  isMobile: boolean;

  loggedInUser: User = {} as User;
  authUser: any;
  textCopied: boolean = false;
  modalReference: any = null;
  ads: Array<Ads> = [
    {
      heading: 'Star us on Github ⭐',
      message: 'Help us to spread the word in developer community, it will take a few sec...',
      url: 'https://github.com/dayschedule/dayschedule-widget',
      cta: 'Open Github',
      days: 30,
    },
    {
      heading: 'Upgrade your plan',
      message: 'Looks like you are currently on a free plan. Consider upgrading your plan to access premium features and API.',
      url: '/billings/upgrade',
      cta: 'Upgrade now',
      plan: ['Free'],
      days: 3,
    },
    {
      heading: 'Change logs',
      url: '/change-log',
      days: 7,
    },
  ];

  promoAd: Ads | undefined;

  @ViewChild('changeLogModal', { static: true }) changeLogModal: any;

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private router: Router,
    private modalService: NgbModal,
    public copyService: CopyToClipboardService,
    private notificationService: NotificationService
  ) {
    this.isMobileApp = navigator.userAgent == 'com.dayschedule';
  }

  ngOnInit(): void {
    this.detectScreenSize();
    this.authUser = this.authService.getUser();
    this.refreshUser();

    timer(5000) // Emits after 3 seconds
      .pipe(take(1)) // Take only one emission
      .subscribe(() => {
        this.displayAd();
      });
  }

  @HostListener('window:resize', [])
  onResize() {
    this.detectScreenSize();
  }

  detectScreenSize() {
    this.isMobile = window.innerWidth < 768;
  }

  logoutUser() {
    this.authService.logout();
  }

  refreshUser() {
    this.profileService.getProfile().subscribe(
      (data: any) => {
        this.authService.saveUser(data);
      },
      (err) => {}
    );
  }

  closeModal() {
    this.modalReference.close();
  }

  openModal(confirm: any) {
    this.modalReference = this.modalService.open(confirm);
  }

  openEmbedModal(domain: string) {
    const modalRef = this.modalService.open(EmbedComponent, { backdropClass: 'modal-backdrop', size: 'lg' });
    modalRef.componentInstance.domain = domain;
  }

  getRandomAd() {
    return this.ads[Math.floor(Math.random() * this.ads.length)];
  }

  wasAdShownRecently(ad: Ads) {
    const adLastShown = localStorage.getItem(`lastAdShown_${ad.heading}`);
    if (adLastShown) {
      const lastShownDate = new Date(adLastShown);
      const now = new Date();
      return now.getTime() - lastShownDate.getTime() < ad.days * 24 * 60 * 60 * 1000;
    }
    return false;
  }

  displayAd() {
    const oneDaysAgo: Date = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000);
    if (this.authUser.created_at && new Date(this.authUser.created_at) > oneDaysAgo) return;

    const ad = this.getRandomAd();
    if (!this.wasAdShownRecently(ad)) {
      if (ad.url == '/change-log') {
        this.showChangeLogs();
      } else if (!ad.plan || ad.plan.includes(this.authUser.plan)) {
        this.promoAd = ad;
      }
      localStorage.setItem(`lastAdShown_${ad.heading}`, new Date().toISOString());
    }
  }

  changeLogs: any;
  showChangeLogs() {
    if (this.isMobileApp || this.router.url == '/onboarding') return;

    const lastReadDate = localStorage.getItem('changeLogReadDate');
    this.notificationService.getChangeLog().subscribe(
      (data: any) => {
        this.changeLogs = data;
        const lastPost = this.changeLogs.post_stream?.posts[0];
        if (!lastPost) return;
        if (lastReadDate && new Date(lastReadDate) > new Date(lastPost.created_at)) return;

        // Show change logs
        localStorage.setItem('changeLogReadDate', new Date().toISOString());
        this.modalReference = this.modalService.open(this.changeLogModal, { backdropClass: 'modal-backdrop', size: 'lg', scrollable: true });
      },
      (err) => {}
    );
  }

  closeAds() {
    this.promoAd = undefined;
  }
}
