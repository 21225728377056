<div class="row">
  <div class="col-md-6 offset-md-3 mt-3">
    <div class="card shadow">
      <div class="card-header text-center bg-geometry">
        <h1 class="h3 text-gray-900 mb-3">Reedem Coupon</h1>
      </div>
      <div class="card-body text-center p-4">
        <div class="text-center">
          <p class="text-gray-800">If you have purchased a deal from our partner websites. Enter your coupon code here to upgrade your subscription</p>
          <form #form="ngForm" (ngSubmit)="onSubmit()" autocomplete="off" class="mx-5">
            <div class="input-group mb-3">
              <input name="couponCode" type="text" minlength="10" [(ngModel)]="couponCode" class="form-control" placeholder="Coupon code..." aria-label="Coupon code" required />
              <div class="input-group-append">
                <button type="submit" class="btn btn-primary" [disabled]="!form.valid || loading">
                  <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                  Apply
                </button>
              </div>
            </div>
          </form>

          <hr />
          <p class="text-muted mx-auto mt-3 small">
            The deals are valid for single user licenses only for users who do not have an existing paid account on DaySchedule.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
