import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Notifications } from '../model/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private httpClient: HttpClient) {}

  getNotifications() {
    return this.httpClient.get(environment.apiBaseUrl + `/notifications?offset=0&limit=10`);
  }

  markAsRead() {
    return this.httpClient.put(environment.apiBaseUrl + `/notifications`, {});
  }

  // Admin or Staff only
  pushNotification(notification: Notifications) {
    return this.httpClient.post(environment.apiBaseUrl + `/notifications`, notification);
  }

  getChangeLog(){
    return this.httpClient.get(environment.apiBaseUrl + `/changelog`);
  }

}
