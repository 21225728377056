import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { catchError, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private cache = new Map<
    string,
    { response: HttpResponse<any>; timestamp: number }
  >();
  private cachable = new Map<string, { ttl: number }>([
    ['/users', { ttl: 3600 }],
    ['/timezones', { ttl: 3600 }],
    ['/me', { ttl: 15 }],
    ['/pages', { ttl: 15 }],
    ['/changelog', { ttl: 3600 }],
  ]);

  private isTestEnv = false;
  constructor(
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isTestEnv =
      this.document.location.hostname == 'app.dayschedule.in' ? true : false;
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Add auth header with jwt if user is logged in and request is to the api url
    const authToken = this.authService.getToken();

    const url = this.isTestEnv
      ? request.url.replace(
          'https://api.dayschedule.com',
          'https://api.dayschedule.in'
        )
      : request.url;

    const currentTime = new Date().getTime() / 1000;

    if (this.isApiUrl(request.url)) {
      // Get cache
      const cacheApi = this.cachable.get(
        new URL(url).pathname.replace('/v1', '')
      );
      const cacheKey = request.urlWithParams + authToken;
      if (cacheApi && request.method == 'GET' && this.cache.has(cacheKey)) {
        const cachedItem = this.cache.get(cacheKey);
        if (cachedItem) {
          const { response, timestamp } = cachedItem;
          if (currentTime - timestamp <= cacheApi.ttl) return of(response);
          else this.cache.delete(cacheKey);
        }
      }

      // Set auth token
      request = request.clone({
        url: url,
        setHeaders: authToken
          ? {
              Authorization: `Bearer ${authToken}`,
            }
          : {},
      });

      // Set or expire cache
      if (cacheApi) {
        return next.handle(request).pipe(
          tap((response) => {
            if (response instanceof HttpResponse) {
              if (request.method == 'GET')
                this.cache.set(cacheKey, {
                  response: response,
                  timestamp: currentTime,
                });
              else this.cache.delete(cacheKey);
            }
          })
        );
      }
    }
    return next.handle(request);
  }

  isApiUrl(url: string) {
    return (
      url.startsWith(environment.apiBaseUrl) ||
      url.startsWith(environment.testApiUrl)
    );
  }
}
