import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Menu } from '../../model/menu';
import { RoleType } from '../../model/user';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  sidebarToggled: boolean = false;
  authUser: any;
  isStaff: boolean = false;
  isAdmin: boolean = false;
  isMobileApp: boolean;

  menus: Array<Menu>;
  menus2: Array<Menu>;
  menus3: Array<Menu>;

  constructor(private authService: AuthService, private router: Router) {
    const menus = this.router.config
      .filter((x: any) => x.data?.menu)
      .map((m: any) => ({
        name: m.data.title,
        url: m.path,
        icon: m.data.icon,
        permissions: m.data.permissions,
        level: m.data.level,
      }));

    const footers = ['settings', 'billings'];
    const mobileMenus = ['resources', 'bookings', 'crm', 'pages', 'dashboard'];

    this.menus = menus.filter((x) => !footers.includes(x.url));
    this.menus2 = menus.filter((x) => footers.includes(x.url));
    this.menus3 = menus.filter((x) => mobileMenus.includes(x.url));

    this.isMobileApp = navigator.userAgent == 'com.dayschedule';
  }

  ngOnInit(): void {
    this.authUser = this.authService.getUser();
    if (!this.authUser) {
      this.authService.logout();
      return;
    }
    this.setProfileUrl();
    if (this.authUser.role != RoleType.Owner) {
      const i = this.menus2.findIndex((x) => x.url == 'settings');
      if (i > -1) {
        this.menus2[i].url = `settings/profile`;
      }
    }
  }

  setProfileUrl() {
    if (this.authUser.role == RoleType.Owner) return;

    const index = this.menus.findIndex((x) => x.name == 'Settings');
    if (index > -1) {
      this.menus[index].url = '/settings/profile';
    }
  }

  checkPermission(menu: Menu) {
    return this.authService.checkPermission(menu, this.authUser);
  }
}
