import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrHelper } from '../helper/toastr-helper';
import { SubscriptionService } from '../services/subscription.service';

@Component({
  selector: 'app-redeem-coupon',
  templateUrl: './redeem-coupon.component.html',
})
export class RedeemCouponComponent implements OnInit {
  loading: boolean = false;
  couponCode: string;

  constructor(private subscriptionService: SubscriptionService, private toastr: ToastrHelper, private router: Router) {}

  ngOnInit(): void {}

  onSubmit() {
    this.applyCoupon();
  }

  applyCoupon() {
    this.loading = true;
    this.subscriptionService.redeemCoupon(this.couponCode).subscribe(
      (response: any) => {
        this.loading = false;
        this.toastr.handleSuccess(response.message);
        this.router.navigate(['/billings']);
      },
      (err) => {
        this.loading = false;
        this.toastr.handleError(err);
      }
    );
  }
}
