import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { NgForm } from '@angular/forms';
import { ToastrHelper } from '../../helper/toastr-helper';
import { SignupRequest } from '../../model/models';
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmOtpComponent } from '../../_shared/confirm-otp/confirm-otp.component';
import { OTPPurpose } from '../../model/otpVerification';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
})
export class SignupComponent implements OnInit {
  user: SignupRequest = {} as SignupRequest;
  loading: boolean = false;
  googleAuthUri: string;

  constructor(
    private authService: AuthService,
    private toastr: ToastrHelper,
    private router: Router,
    private recaptchaV3Service: ReCaptchaV3Service,
    private modalService: NgbModal,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.googleAuthUri = `${environment.getApiBaseUri(
      this.document.location.hostname
    )}/oauth/google?reason=consent`;
  }

  ngOnInit(): void {}

  signup(capthcaToken: string) {
    this.authService
      .signup({ ...this.user, recaptcha: capthcaToken })
      .subscribe(
        (data) => {
          this.loading = false;
          this.authService.saveToken(data.token);
          this.authService.saveUser(data);
          this.reVerify();
        },
        (err) => {
          this.toastr.handleError(err);
          this.loading = false;
        }
      );
  }

  reVerify() {
    const modalRef = this.modalService.open(ConfirmOtpComponent);
    modalRef.componentInstance.otp = { purpose: OTPPurpose.signup };
    modalRef.componentInstance.btnText = 'Verify';
    modalRef.componentInstance.btnClass = 'btn-primary';
    modalRef.componentInstance.title = 'Verification';
    modalRef.componentInstance.description = `Verify your email address, by entering the OTP sent on your email:`;

    modalRef.result.then(
      (result) => {
        this.authService.saveToken(result.token);
        this.authService.saveUser(result);
        this.router.navigate(['/onboarding']);
      },
      (reason) => {}
    );
  }

  signupWithRecaptcha() {
    this.loading = true;
    this.recaptchaV3Service.execute('signup').subscribe(
      (token) => {
        this.signup(token);
      },
      (error) => {
        this.loading = false;
        this.toastr.handleError(error);
        console.error(error);
      }
    );
  }

  onSubmit(form: NgForm) {
    this.signupWithRecaptcha();
  }
}
