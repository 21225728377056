import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrHelper } from 'src/app/helper/toastr-helper';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'verification',
  templateUrl: './verification.component.html',
})
export class VerificationComponent implements OnInit {
  message: string = 'Wait...';
  status: number = 0;
  loggedUser: any;
  loading: boolean = true;
  returnUrl: string;
  token: any;
  constructor(
    private authService: AuthService,
    private router: Router,
    private activateRouter: ActivatedRoute,
    private toastr: ToastrHelper
  ) {}

  ngOnInit(): void {
    this.activateRouter.queryParams.subscribe((params) => {
      this.token = params.token;
    });

    this.verifyAndSignin();
  }

  verifyAndSignin() {
    this.authService.verifyUser(this.token).subscribe(
      (response) => {
        this.status = 200;
        this.authService.saveToken(response.token);

        // Set is_verified=1 in login session
        response.is_verified = 1;
        this.authService.saveUser(response);

        this.loading = false;
        this.loggedUser = response;
        const redirectURL = this.authService.getRedirectUrl(
          this.activateRouter.snapshot.queryParams['returnUrl']
        );
        this.router.navigate([redirectURL]);
      },
      (err) => {
        if (err.error) {
          this.status = err.error.status_code;
          this.message = err.error.message;
        } else {
          this.status = 400;
          this.message = 'Invalid or expired token';
        }
        //this.toastr.handleError(err);
        this.loading = false;
      }
    );
  }
}
