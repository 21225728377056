import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrHelper } from '../../helper/toastr-helper';
import { JoinUser } from '../../model/models';
import { AuthService } from '../../services/auth.service';
import { Utility } from '../../helper/utility';

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.css'],
})
export class JoinComponent implements OnInit {
  user: JoinUser = {} as JoinUser;
  token: string;
  loading: boolean = false;
  loggedUser: any;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrHelper,
    private authService: AuthService,
    private utility: Utility,
  ) {}

  ngOnInit(): void {
    this.user.time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.token = this.route.snapshot.queryParams.token;
  }

  onSubmit(form: NgForm) {
    this.joinAccount();
  }

  joinAccount() {
    this.loading = true;
    this.authService.joinAccount(this.token, this.user).subscribe(
      (response) => {
        this.loading = false;
        // updating session to logged in user directly after join
        this.authService.saveToken(response.token);
        this.authService.saveUser(response);
        this.loading = false;
        this.loggedUser = response;
        this.router.navigate(['/onboarding']);
      },
      (err) => {
        this.toastr.handleError(err);
        this.loading = false;
      }
    );
  }
}
