export enum LevelType {
  Level1 = 'Level1',
  Level2 = 'Level2',
  Level3 = 'Level3',
}

export enum RoleType {
  Owner = 'Owner',
  Admin = 'Admin',
  Custom = 'Custom',
}

export enum PermissionType {
  'user.read' = 'user.read',
  'user.create' = 'user.create',
  'user.update' = 'user.update',
  'user.delete' = 'user.delete',

  'resource.read' = 'resource.read',
  'resource.create' = 'resource.create',
  'resource.update' = 'resource.update',
  'resource.delete' = 'resource.delete',

  'contact.read' = 'contact.read',
  'contact.create' = 'contact.create',
  'contact.update' = 'contact.update',
  'contact.delete' = 'contact.delete',

  'workflow.read' = 'workflow.read',
  'workflow.create' = 'workflow.create',
  'workflow.update' = 'workflow.update',
  'workflow.delete' = 'workflow.delete',

  'booking.read' = 'booking.read',
  'booking.create' = 'booking.create',
  'booking.update' = 'booking.update',
  'booking.delete' = 'booking.delete',

  'apikey.read' = 'apikey.read',
  'apikey.create' = 'apikey.create',
  'apikey.update' = 'apikey.update',
  'apikey.delete' = 'apikey.delete',
}

export class User {
  user_id: number;
  name?: string;
  email?: string;
  role?: RoleType = RoleType.Custom;
  permissions?: string;
  status?: string;
  avatar?: string;
  dial_code?: string;
  phone?: string;
  domain: string;
  plan?: string;
  location?: string;
  team?: string;
  is_verified?: boolean;
  team_access?: boolean;
  is_subscribed?: boolean;
  time_zone?: string;
  date_time_format?: string;
  last_login_at?: Date;
  created_at?: Date;
  updated_at?: Date;
}
