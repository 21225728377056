import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private httpClient: HttpClient) {}

  getProfile() {
    return this.httpClient.get(environment.apiBaseUrl + '/me');
  }

  updateProfile(user: any) {
    return this.httpClient.put(environment.apiBaseUrl + `/me`, user);
  }

  updateToken(data: any) {
    return this.httpClient.put(environment.apiBaseUrl + `/me/token`, data);
  }
}
