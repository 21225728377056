import { Component, OnInit } from '@angular/core';
import { ToastrHelper } from '../../helper/toastr-helper';
import { ForgotRequest } from '../../model/models';
import { AuthService } from 'src/app/services/auth.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  user: ForgotRequest = {} as ForgotRequest;
  loading: boolean = false;
  constructor(
    private authService: AuthService,
    private toastr: ToastrHelper,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit(): void {}

  forgotMyPassword(recaptchaToken: string) {
    this.user.recaptcha = recaptchaToken;
    this.authService.forgotPassword(this.user).subscribe(
      (response) => {
        this.toastr.handleSuccess(response.message);
        this.loading = false;
      },
      (err) => {
        this.toastr.handleError(err);
        this.loading = false;
      }
    );
  }

  resetWithRecaptcha() {
    this.loading = true;
    this.recaptchaV3Service.execute('fogotPassword').subscribe(
      (token) => {
        this.forgotMyPassword(token);
      },
      (error) => {
        this.loading = false;
        this.toastr.handleError(error);
        console.error(error);
      }
    );
  }

  onSubmit() {
    this.resetWithRecaptcha();
  }
}
