import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { NgForm } from '@angular/forms';
import { ToastrHelper } from '../../helper/toastr-helper';
import { ResetPassword } from '../../model/models';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css'],
})
export class ResetComponent implements OnInit {
  user: ResetPassword = {} as ResetPassword;
  loading: boolean = false;
  isConfirm: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private toastr: ToastrHelper
  ) {}

  ngOnInit(): void {}

  onSubmit(form: NgForm) {
    this.userResetPassword(this.user);
  }

  userResetPassword(user: ResetPassword) {
    this.loading = true;
    const { token } = this.route.snapshot.queryParams;
    this.authService.changePassword(token, user).subscribe(
      (response) => {
        this.toastr.handleSuccess(response.message);
        this.loading = false;
        this.router.navigate(['login']);
      },
      (err) => {
        this.toastr.handleError(err);
        this.loading = false;
      }
    );
  }
}
