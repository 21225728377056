import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { OtpVerification } from '../model/otpVerification';

@Injectable({
  providedIn: 'root',
})
export class OtpService {
  constructor(private httpClient: HttpClient) {}

  sendOtp(otp: OtpVerification): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/otp/send', otp);
  }

  verifyOtp(otp: OtpVerification): Observable<any> {
    return this.httpClient.post(environment.apiBaseUrl + '/otp/verify', otp);
  }
}
