import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CopyToClipboardService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public copy(value: string | undefined) {
    if (!value) return;
    this.document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData?.setData('text/plain', value);
      e.preventDefault();
      this.document.removeEventListener('copy', () => {
        return null;
      });
    });
    this.document.execCommand('copy');
  }
}
